import { Box, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { currencyFormat } from "../../../../utils";

function BasicCard({ oferta, handleSeleccionarOferta }) {
  // eslint-disable-next-line
  const theme = useTheme();
  // const main = oferta.main;
  const dataset = useSelector((state) => state.config.dataset);
  const value = oferta.maximoMonto;
  const moneda = oferta.monedaExpresion;
  const handleClick = () => {
    handleSeleccionarOferta(oferta, value);
  };
  return (
		<Card
			sx={{
				border: oferta.seleccionado ? "3px solid" : "0px solid",
				borderColor: oferta.seleccionado ? "primary.main" : "transparent",
			}}
			elevation={3}
		>
			<CardContent>
				<Box sx={{ textAlign: "center", marginY: "2rem" }}>
					<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
						Oferta
					</Typography>
					<Typography variant="h5" component="div">
						{moneda} {value && currencyFormat(value)}
					</Typography>
					{oferta.cotizacion && oferta.cotizacion !== 1 ? (
						// <Typography
						//   sx={{ fontSize: 13, minHeight: "1.5rem" }}
						//   color="text.secondary"
						//   gutterBottom
						// >
						//   ({dataset.monedaNacional}{" "}
						//   {value &&
						//     oferta.cotizacion && currencyFormat(Number(value) * Number(oferta.cotizacion))}
						//   )
						// </Typography>
						<Box sx={{ minHeight: "1.5rem" }} />
					) : (
						<Box sx={{ minHeight: "1.5rem" }} />
					)}
				</Box>
			</CardContent>
			<CardActions sx={{ justifyContent: "center" }}>
				<Button
					className="button"
					variant="contained"
					size="small"
					color="button"
					onClick={() => handleClick()}
				>
					Seleccionar
				</Button>
			</CardActions>
		</Card>
	);
}

export default BasicCard;
